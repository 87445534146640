@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

$body-bg: #191c21;
$body-color: #fff;

$theme-colors: (
  "site-primary": #000,
  "site-secondary": #fff,
  "site-mutedd": #8fa2bb,
  "site-heading": #1b1d58,
  "site-para": #677a9d,
  "site-nav-link": #060a0d,
);
@import "node_modules/bootstrap/scss/bootstrap";

@font-face {
  // font-family: MontserratAlternates-Regular;
  // src: url(../Fonts/montserrat-alternates/MontserratAlternates-Regular.ttf);
}
.font-family {
  // font-family: MontserratAlternates-Regular;
}
.font-family-nav {
  font-family: "Questrial", sans-serif;
}
body {
  background-color: $body-bg;
  color: $body-color;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
}
::-webkit-scrollbar-track {
  background: theme-color("site-secondary1");
}
::-webkit-scrollbar-thumb {
  background-color: theme-color("site-muted"); /* color of the scroll thumb */
  border-radius: 1.25rem; /* roundness of the scroll thumb */
}
ol {
  list-style-type: upper-alpha;
}
.display-4 {
  font-weight: 700;
  @media (max-width: 1450px) {
    font-size: 3rem;
  }
  @media (max-width: 1350px) {
    font-size: 2.85rem;
  }
  @media (max-width: 1150px) {
    font-size: 2.65rem;
  }
  @media (max-width: 1050px) {
    font-size: 2.45rem;
  }
  @media (max-width: 990px) {
    font-size: 2.2rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 500px) {
    font-size: 2.2rem;
  }
  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
}
h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin: 0;
  color: #fff;
  @media (max-width: 1450px) {
    font-size: 2.5rem;
  }
  @media (max-width: 1350px) {
    font-size: 2.15rem;
  }
  @media (max-width: 1150px) {
    font-size: 1.9rem;
  }
  @media (max-width: 1050px) {
    font-size: 1.7rem;
  }
  @media (max-width: 990px) {
    font-size: 1.7rem;
  }
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
  @media (max-width: 500px) {
    font-size: 1.7rem;
  }
  @media (max-width: 400px) {
    font-size: 1.75rem;
  }
}
h2 {
  color: #fff;
  font-weight: 700;
  font-size: 2.1rem;
  @media (max-width: 1450px) {
    font-size: 1.83rem;
  }
  @media (max-width: 1350px) {
    font-size: 1.61rem;
  }
  @media (max-width: 1250px) {
    font-size: 1.65rem;
  }
  @media (max-width: 1150px) {
    font-size: 1.35rem;
  }
  @media (max-width: 1050px) {
    font-size: 1.15rem;
  }
  @media (max-width: 990px) {
    // text-align: justify;
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    font-size: 1.13rem;
  }
  @media (max-width: 500px) {
    font-size: 1.54rem;
  }
}
h3 {
  color: #fff;
  // font-weight: 700;
  font-size: 1.85rem;
  @media (max-width: 1450px) {
    font-size: 1.62rem;
  }
  @media (max-width: 1300px) {
    font-size: 1.42rem;
  }
  @media (max-width: 1200px) {
    font-size: 1.32rem;
  }
  @media (max-width: 1100px) {
    font-size: 1.22rem;
  }
  @media (max-width: 990px) {
    // text-align: justify;
    font-size: 1.06rem;
  }
  @media (max-width: 768px) {
    font-size: 0.81rem;
  }
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
}
h4 {
  font-weight: normal;
  font-size: 1.5rem;
  @media (max-width: 1450px) {
    font-size: 1.4rem;
  }
  @media (max-width: 1300px) {
    font-size: 1.33rem;
  }
  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
  @media (max-width: 1100px) {
    font-size: 1.15rem;
  }
  @media (max-width: 990px) {
    font-size: 1.05rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
  @media (max-width: 400px) {
    font-size: 1rem;
  }
}

h5 {
  font-weight: normal;
  margin: 2rem 0;
  @media (max-width: 1450px) {
    font-size: 1.1rem;
  }
  @media (max-width: 1150px) {
    font-size: 0.9rem;
  }
  @media (max-width: 990px) {
    font-size: 0.9rem;
  }
  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
}

p {
  margin-right: 25px !important;
  margin: 2rem 0;
  @media (max-width: 1450px) {
    font-size: 0.97rem;
    margin: 0 !important;
  }
  @media (max-width: 1340px) {
    font-size: 0.9rem;
    margin-right: -50px !important;
  }
  @media (max-width: 1150px) {
    font-size: 0.9rem;
    margin-right: -100px !important;
  }
  @media (max-width: 990px) {
    font-size: 0.87rem;
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    font-size: 0.82rem;
  }
  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
}

.form-box {
  font-size: 12px;
  .form-icon {
    top: 50%;
    left: 20px;
    transform: translateY(-50%) scaleX(-1);
    // font-size: 13px;
    color: theme-color("site-secondary") !important;
  }
  input {
    font-size: 12px;
  }
}

//landing page



.topnav{
.not_scrolled {
  background-color: transparent;
  padding-left: 8%;
  padding-right: 8%;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 200;
  @media (max-width: 990px) {
    border: 0;
    padding-left: 3%;
    padding-right: 3%;
    box-shadow: none;
    position: fixed;
    width: -webkit-fill-available;
    height: auto;
  }
}
.navbar-style {
  // font-family: "Nunito", sans-serif;
  padding-left: 3%;
  padding-right: 3%;
  position: fixed;
  width: 100%;
  height: 90px;
  background-color: #fff;
  z-index: 200;
  @media (max-width: 990px) {
    border: 0;
    padding-left: 3%;
    padding-right: 3%;
    box-shadow: none;
    position: fixed;
    width: -webkit-fill-available;
    height: auto;
  }
}
.navbar-header {
  width: 17%;
  margin-left: 1rem;
  @media (max-width: 990px) {
    z-index: 200;
    // width: 14%;
  }
  @media (max-width: 768px) {
    z-index: 200;
    // width: 7%;
  }
  @media (max-width: 450px) {
    z-index: 200;
    width: 35%;
  }
}
.navbar-collapse {
  @media (max-width: 990px) {
    // margin-top: -100px;
    background-color: #ffffff26;
  }
}
.navbar-nav {
  @media (max-width: 990px) {
    // margin-top: 90px;
  }
}
.nav-item {
  margin: auto;
  @media (max-width: 990px) {
    margin-right: 10px;
    margin-left: auto;
    padding-top: 100px;
    padding: 10px 0;
  }
  // @media (max-width: 768px) {
  // padding: 0;
  // }
  .navlink {
    padding: 10px 30px;
    color: #292836;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: theme-color("site-primary");
    }
    @media (max-width: 1220px) {
      padding: 10px 15px;
    }
    @media (max-width: 1045px) {
      padding: 10px 10px;
    }
    @media (max-width: 768px) {
      padding: 0px;
      font-size: 0.8rem;
    }
  }
  .navlink-active {
    color: theme-color("site-primary");
    // font-weight: 600;
    background-color: transparent;
    text-decoration: none;
    // border-bottom: 2px solid theme-color('site-primary');
  }
}
.nav-btn {
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 5px 20px;
  margin: 0px 10px;
}
// }
}
.navbar-toggler {
  z-index: 200;
}
.navbar-toggler-icon {
  background-image: url("../img/chirp-imgs/download.svg");
  background-position: top;
  background-repeat: no-repeat;
  z-index: 200;
  cursor: pointer;
}
.active {
  .navbar-toggler-icon {
    background-image: url("../img/chirp-imgs/times.png") !important;
    background-position: top;
    z-index: 200;
    background-repeat: no-repeat;
  }
}

//Home
.home {
  margin-top: 100px;
  @media (max-width: 575px) {
  margin-top: 60px;
  }
  .div-1 {
    background-color: #191c21;
    .f-row1 {
      margin: auto 3% 0 3%;
      // height: 600px;
      padding: 3rem 0rem;
      @media (max-width: 1450px) {
        // margin: auto 5rem 0 5rem;
      }
      @media (max-width: 1150px) {
        // margin: auto 3rem 0 3rem;
      }
      @media (max-width: 990px) {
        // margin-top: 0;
      }
      @media (max-width: 768px) {
        padding: 50px 0;
        margin: auto 1% 0 1%;
      }
      @media (max-width: 575px) {
        padding: 0;
        // margin-left: 0;
        // margin: auto;
      }
      .f-colr {
        margin: auto !important;
        // margin-bottom: 5rem !important;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // margin-left: 15%;
        div {
          @media (max-width: 1340px) {
            padding: 0 !important;
            margin-left: 0 !important;
          }
          @media (max-width: 1150px) {
            padding: 0;
          }
          @media (max-width: 600px) {
            // text-align: center;
            margin-top: 30px;
          }
          h1 {
            margin: 0;
          }
          h5 {
            font-weight: normal;
          }
        }
      }
      .f-coll {
        // padding: 0 5rem;
        margin: auto;
        // margin-bottom: 0 !important;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        @media (max-width: 1150px) {
          padding: 0;
        }
        img {
          // padding-left: 270px;
          // width: 100%;
          @media (max-width: 1450px) {
            // padding-left: 200px;
          }
          @media (max-width: 1300px) {
            // padding-left: 130px;
          }
          @media (max-width: 1050px) {
            // padding-left: 80px;
          }
          @media (max-width: 768px) {
            padding: 0;
          }
          @media (max-width: 575px) {
            padding: 0;
            // width: 80%;
            // margin: auto !important;
            // margin-left: 70px;
            text-align: center;
            padding-bottom: 30px;
          }
        }
      }
    }
  }
  .div-2 {
    // background-image: url("../img/chirp-imgs/ACall-bg.png");
    // background-position: bottom;
    // background-repeat: no-repeat;
    // background-size: contain;
    background-color: #fff;
    .f-row2 {
      margin: auto 3% 0 3%;
      // margin-top: -5rem;
      // height: 600px;
      padding: 3rem 0rem;
      // height: 100%;
      @media (max-width: 1450px) {
        // margin: auto 5rem 0 5rem;
      }
      @media (max-width: 1150px) {
        // margin: auto 3rem 0 3rem;
      }
      @media (max-width: 990px) {
        // margin-top: 0;
      }
      @media (max-width: 768px) {
        padding: 50px 0;
        // margin: auto 2rem 0 2rem;
      }
      @media (max-width: 575px) {
        padding: 30px 0 0px;
        // margin-left: 0;
        // margin: auto;
      }
      .f-coll {
        margin: auto !important;
        // margin-bottom: 5rem !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-left: 15%;

        div {
          @media (max-width: 1340px) {
            padding: 0 !important;
            margin-left: 1rem !important;
          }
          @media (max-width: 1150px) {
            padding: 0;
          }
          h1 {
            margin: 0;
          }
          h5 {
            font-weight: normal;
          }
        }
      }
      .f-colr {
        // padding: 0 3rem;
        margin: auto;
        // margin-bottom: 0 !important;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        @media (max-width: 1150px) {
          padding: 0;
        }
        img {
          // padding-left: 270px;
          // width: 100%;
          @media (max-width: 1450px) {
            // padding-left: 200px;
          }
          @media (max-width: 1300px) {
            // padding-left: 130px;
          }
          @media (max-width: 1050px) {
            // padding-left: 80px;
          }
          @media (max-width: 768px) {
            padding: 0;
          }
          @media (max-width: 575px) {
            padding: 0;
            // width: 80%;
            // margin: auto !important;
            // margin-left: 70px;
            text-align: center;
          }
        }
        .pa-img {
          @media (max-width: 575px) {
            width: 70%;
          }
        }
      }
      #fcolr-pa {
        padding: 6.3rem;
        @media (max-width: 1500px) {
          padding: 5.5rem;
        }
        @media (max-width: 1300px) {
          padding: 2rem 5rem;
        }
        @media (max-width: 1200px) {
          padding: 1rem 3rem;
        }
        @media (max-width: 850px) {
          padding: 0 2rem;
        }
        @media (max-width: 650px) {
          padding: 0 3rem;
        }
      }
    }
  }
  #p-aud-div {
    background-image: url("../img/chirp-imgs/paud-bg.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #p-vdio-div {
    background-image: url("../img/chirp-imgs/p-vdio-bg.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #p-aud {
    // height: 700px !important;
  }
  .bg-circle {
    background-image: url("../img/chirp-imgs/Ellipse\ 21.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto;
    margin-left: -22rem;
  }
  .div-col {
    background-color: white;
    // margin: 5rem 9rem;
    // @media (max-width: 1200px) {
    //   margin: 3rem 0rem;
    // }
    // @media (max-width: 900px) {
    //   margin: 2rem 0rem;
    // }
    // @media (max-width: 600px) {
    //   margin: 3rem 0rem;
    // }
    // @media (max-width: 400px) {
    //   margin: 2rem 0rem;
    // }
    // h1 {
    //   margin: 0;
    //   text-align: center;
    // }
    .div-com {
      margin: auto 3% 0 3%;
      padding: 3rem 0rem;
      // height: 600px;
      // @media (max-width: 1200px) {
      //   margin: 0rem 4rem;
      // }
      // @media (max-width: 900px) {
      //   margin: 0rem 1rem;
      // }
      @media (max-width: 600px) {
        margin: auto 1% 0 1%;
      }
      // @media (max-width: 400px) {
      //   margin: 0rem 1rem;
      // }
      .card {
        // padding: 50px 60px;
        // margin: 60px 30px;
        border: 0;
        // display: flex;
        // flex-direction: column;
        // justify-content: left;
        // align-items: center;
        // text-align: left;
        border-radius: 10px;
        // @media (max-width: 1450px) {
        //   margin: 60px 20px;
        //   padding: 50px 55px;
        // }
        // @media (max-width: 1300px) {
        //   margin: 50px 15px;
        //   padding: 45px 40px;
        // }
        // @media (max-width: 900px) {
        //   margin: 50px 10px;
        //   padding: 35px 20px;
        // }
        // @media (max-width: 600px) {
        //   margin: 30px 10px;
        //   padding: 30px 40px;
        // }
        img {
          // margin: 20px;
          // width: 50%;
        }
        h2 {
          font-weight: normal;
        }
      }
    }
  }
}

//about
.about {
  .a-banner {
    background-image: url("../img/chirp-imgs/about\ banner-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @media (max-width: 1150px) {
      height: 400px;
    }
    @media (max-width: 800px) {
      height: 300px;
    }
    @media (max-width: 600px) {
      height: 200px;
    }
    h1 {
      text-transform: uppercase;
    }
  }
  .div-1 {
    background-color: #ffff;
    padding-top: 100px;
    .f-row1 {
      margin: auto 3% 0 3%;
      // height: 600px;
      padding: 3rem 0rem;
      @media (max-width: 1450px) {
        // margin: auto 5rem 0 5rem;
      }
      @media (max-width: 1150px) {
        // margin: auto 3rem 0 3rem;
      }
      @media (max-width: 990px) {
        // margin-top: 0;
      }
      @media (max-width: 768px) {
        padding: 50px 0;
        // margin: auto 2rem 0 2rem;
      }
      @media (max-width: 575px) {
        padding: 80px 0 0px;
        // margin-left: 0;
        // margin: auto;
      }
      .f-colr {
        margin: auto !important;
        // margin-bottom: 5rem !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-left: 15%;
        div {
          @media (max-width: 1150px) {
            padding: 0 !important;
          }
          @media (max-width: 600px) {
            text-align: center;
          }
          h1 {
            margin: 0;
          }
          h5 {
            margin: 2rem 12rem 2rem 0;
            @media (max-width: 1450px) {
              margin: 2rem 13rem 2rem 0;
            }
            @media (max-width: 1300px) {
              margin: 2rem 8rem 2rem 0;
            }
            @media (max-width: 900px) {
              margin: 2rem 2rem 2rem 0rem;
            }
            @media (max-width: 600px) {
              margin: 2rem 0rem;
            }
          }
        }
      }
      .f-coll {
        // padding: 0 3%;
        margin: auto;
        // margin-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 1150px) {
          padding: 0;
        }
        img {
          // padding-left: 270px;
          @media (max-width: 575px) {
            padding: 0;
            width: 60%;
            // margin: auto !important;
            // margin-left: 70px;
            text-align: center;
          }
        }
      }
    }
  }
  .div-2 {
    // background-color: ;
    .f-row2 {
      margin: auto 3% 0 3%;
      // margin-top: -5rem;
      // height: 600px;
      padding: 3rem 0rem;
      // height: 100%;
      // @media (max-width: 1450px) {
      //   margin: auto 5rem 0 5rem;
      // }
      // @media (max-width: 1150px) {
      //   margin: auto 3rem 0 3rem;
      // }
      // @media (max-width: 990px) {
      //   margin-top: 0;
      // }
      @media (max-width: 768px) {
        padding: 50px 0;
        // margin: auto 2rem 0 2rem;
      }
      @media (max-width: 575px) {
        padding: 80px 0 0px;
        // margin-left: 0;
        // margin: auto;
      }
      .f-coll {
        margin: auto !important;
        // margin-bottom: 5rem !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-left: 15%;

        div {
          @media (max-width: 1150px) {
            padding: 0 !important;
          }
          @media (max-width: 600px) {
            text-align: center;
          }
          h1 {
            margin: 0;
          }
          h5 {
            margin: 2rem 10rem 2rem 0;
            @media (max-width: 1450px) {
              margin: 2rem 11rem 2rem 0;
            }
            @media (max-width: 1300px) {
              margin: 2rem 6rem 2rem 0;
            }
            @media (max-width: 900px) {
              margin: 2rem -1rem 2rem 0;
            }
          }
        }
      }
      .f-colr {
        // padding: 0 3rem;
        margin: auto;
        // margin-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 1150px) {
          padding: 0;
        }
        img {
          // padding-left: 270px;
          // width: 100%;
          @media (max-width: 575px) {
            padding: 0;
            width: 60%;
            margin: auto !important;
            // margin-left: 70px;
            text-align: center;
          }
        }
      }
    }
  }
}

//footer

.page-footer {
  // background-color: white;
  padding-left: 3%;
  padding-right: 3%;
  // background-color: #f2f5ff;
  .navbar-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      text-align: center;
    }
  }
  .navbar-header {
    width: 20%;
    img {
      // width: 90%;
      display: flex;
      margin: auto;
    }
    @media (max-width: 670px) {
      margin: auto;
      text-align: center;
    }
  }
  .footer-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    // margin-left: -250px;
    @media (max-width: 990px) {
      justify-content: center;
      margin-left: 0;
    }
    @media (max-width: 670px) {
      justify-content: center;
      // margin-right: 35px;
    }
    .footer-btn {
      width: 35%;
      cursor: pointer;
      margin: 10px;
      img {
        width: 100%;
      }
    }
  }
  .footer-social {
    margin-left: auto !important;
    justify-content: end;
    display: flex;
    margin: auto;
    @media (max-width: 990px) {
      justify-content: center;
    }
    .social-btn {
      width: 15%;
      @media (max-width: 1450px) {
        width: 100%;
      }
      // margin-right: 20px;
      img {
        width: 100%;
      }
    }
  }
  .footer-navitems {
    display: flex;
    padding-bottom: 50px;
    .navbar-nav {
      flex-direction: row !important;
      .navlink {
        padding: 10px;
        color: #1b1d58;
        font-size: 1rem;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: theme-color("site-primary");
        }
        @media (max-width: 400px) {
          font-size: 0.8rem;
          padding: 5px;
        }
      }
      .nav-btn {
        border-radius: 50px;
        font-size: 1.1rem;
        font-weight: 600;
        padding: 5px 20px;
        margin: 0px 10px;
      }
    }
  }
  .contct {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 50px;
    @media (max-width: 670px) {
      display: grid;
      // margin-top: 20px;
    }
    a {
      color: $body-color;
      text-decoration: none;
      margin-top: 10px;
      img {
        // width: 8%;
        margin-top: -5px;
        margin-right: 10px;
        margin-left: 50px;
        @media (max-width: 870px) {
          margin-right: 5px;
          margin-left: 0;
        }
      }
    }
  }
}

//contact

.Contact {
  margin: auto;
  // margin-top: 50px;
  // height: 170vh;

  .c-banner {
    background-image: url("../img/chirp-imgs/contact\ banner-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h1 {
      font-weight: 700;
    }
    @media (max-width: 500px) {
      height: 300px;
    }
  }

  form {
    padding: 50px 30px;
    margin: 50px 150px;
    text-align: center;
    @media (max-width: 1200px) {
      padding: 40px 25px;
      margin: 50px 130px;
    }
    @media (max-width: 990px) {
      padding: 35px 20px;
      margin: 50px 110px;
    }
    @media (max-width: 830px) {
      padding: 30px 20px;
      margin: 50px 90px;
    }
    @media (max-width: 751px) {
      padding: 15px 15px;
      margin: 50px 60px;
    }
    @media (max-width: 500px) {
      padding: 0px;
      margin: 25px 15px;
    }
    .names {
      display: flex;
      justify-content: space-between;
      .w-names {
        width: 97% !important;
      }
    }
    .form-group {
      margin-bottom: 1.5rem;
      width: 100%;
      @media (max-width: 500px) {
        margin-bottom: 1rem;
      }
      .form-control {
        padding: 3rem 1.75rem;
        border: none;
        font-size: 1.5rem;
        @media (max-width: 1200px) {
          font-size: 1.4rem;
        }
        @media (max-width: 990px) {
          font-size: 1.3rem;
        }
        @media (max-width: 830px) {
          font-size: 1.2rem;
        }
        @media (max-width: 751px) {
          font-size: 1.1rem;
        }
        @media (max-width: 500px) {
          font-size: 0.9rem;
          padding: 1rem;
        }
      }
      .t-area {
        height: 300px;
        @media (max-width: 500px) {
          height: 150px;
        }
      }
    }
    Button {
      padding: 1.5rem 0rem;
      font-size: 1.7rem;
      text-align: center;
      width: 40%;
      font-weight: 900;
      border-radius: 100px;
      margin: 20px auto 10px;
      background-color: theme-color("site-primary");
      @media (max-width: 751px) {
        padding: 1rem 0;
        width: 60%;
        font-size: 1.2rem;
      }
      @media (max-width: 500px) {
        padding: 0.5rem 0;
        width: 40%;
        font-size: 1rem;
      }
    }
  }
}

.tabs__panel {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
}

.tab-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.d-flex {
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link:focus,
.nav-link > a.active {
  background-color: #d1d5e0 !important;
  color: #211db2 !important;
  &:active {
    background-color: transparent !important;
  }
}
.tab__heading {
  color: #060a0d !important;
  text-decoration: none !important;
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}
